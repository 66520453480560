import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from  '@fortawesome/pro-solid-svg-icons/faPlay'
import { faPause } from  '@fortawesome/pro-solid-svg-icons/faPause'

var classNames = require("classnames")

class PlayPause extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: props.language,
      pauseText: "Pause",
      playText: "Play"
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  
    handleKeyPress(event) {
    if(event.key === 'Enter'){
        this.props.handlePlayPause()
    }
  }

  componentDidMount() {
    if (this.props.language === "es"){
      this.setState({
        pauseText: "Pausa",
        playText: "Play"
      });
    }
  }

  render() {

    return (
    <div id="sizzlePause" tabIndex="0" onKeyPress={this.handleKeyPress} onClick={this.props.handlePlayPause} aria-label={this.props.videoPlaying ? this.state.pauseText : this.state.playText} className="sizzle-pause">
      <FontAwesomeIcon icon={(this.props.videoPlaying ? faPause : faPlay)} />
      <span>{this.props.videoPlaying ? this.state.pauseText : this.state.playText}</span>
    </div>
    )
  }
}

export default PlayPause
