import React, { Component } from "react"
import ImageMeta from "../ImageMeta"
import Slider from "react-slick"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link, StaticQuery, graphql } from "gatsby"
import {
  shuffleArray,
  getMonthYearFromStrings,
  sortReviewsByDate,
} from "../../utils/utils"

var classNames = require("classnames")

import { SamplePrevArrow, SampleNextArrow } from "./ChevronArrows"

function SingleSlide(props) {
  let title = props.title
  if (title.charAt(0) !== "/") title = "/" + title

  let nameClasses = classNames("image-caption staff fixed-facewall-name", {
    last: !props.procedure && !props.monthYear,
  })
  let procedureClasses = classNames(
    "image-caption staff fixed-facewall-procedure",
    {
      last: !props.monthYear,
    }
  )
  let timeClasses = classNames("image-caption staff fixed-facewall-time", {
    last: true,
  })
  return (
    <div>
      <Link className="carousel-image-link" to={title.toLowerCase()}>
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.thumbnailPublicId}
          responsive
          responsiveUseBreakpoints="true"
          noLazyload
        >
          <Transformation
            quality="auto"
            fetchFormat="auto"
            crop="scale"
            height="419"
          />
        </ImageMeta>
        <div className="image-caption-wrapper">
          {props.reviewerName && (
            <h6 className={nameClasses}>
              {props.language === "es" ? "Conoce a" : "Meet"}{" "}
              {props.reviewerName}
            </h6>
          )}
          {props.procedure && (
            <h6 className={procedureClasses}>{props.procedure}</h6>
          )}
          {props.monthYear && (
            <h6 className={timeClasses}>
              {getMonthYearFromStrings(
                props.monthYear.year,
                props.monthYear.month,
                props.language
              )}
            </h6>
          )}
        </div>
      </Link>
    </div>
  )
}

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const settings = {
      className: "desktop-slider-375x375",
      accessibility: true,
      adaptiveHeight: false,
      centerMode: false,
      centerPadding: "0px",
      slidesToShow: this.props.language === "es" ? 4 : 5,
      swipeToSlide: true,
      focusOnSelect: false,
      draggable: false,
      lazyLoad: true,
      rows: 1,
      speed: 500,
      nextArrow: <SampleNextArrow targetID={this.props.slideID} />,
      prevArrow: <SamplePrevArrow targetID={this.props.slideID} />,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1125,
          settings: {
            slidesToShow: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            infinite: true,
          },
        },
      ],
    }

    const sliderStyle = {
      width: "auto",
      height: "auto",
      margin: "0px auto",
    }

    const sliderCopy = {
      carouselName: "featured-carousel",
      carouselTime: "December 2019",
      carouselLanguage: "ENG",
      carouselTypeTextTitle: "Patients",
    }

    return (
      <div
        data-aos="no-anim"
        data-aos-offset="400"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        data-aos-id="featured-carousel"
      >
        <StaticQuery
          query={graphql`
            query MyQueryy {
              allReviews: allReviewsJson(
                filter: {
                  reviewType: { eq: "Patient" }
                  reviewLanguage: { ne: "ESP" }
                }
              ) {
                nodes {
                  reviewerName
                  thumbnailPublicId
                  title
                  mainProcedureDone
                  monthYear {
                    month
                    year
                  }
                }
              }
              allSpanishReviews: allReviewsJson(
                filter: {
                  reviewType: { eq: "Patient" }
                  reviewLanguage: { eq: "ESP" }
                }
              ) {
                nodes {
                  reviewerName
                  thumbnailPublicId
                  title
                  mainProcedureDone
                  monthYear {
                    month
                    year
                  }
                }
              }
            }
          `}
          render={(data) => {
            var sortedData
            if (this.props.language === "es") {
              sortedData = sortReviewsByDate(data.allSpanishReviews.nodes)
            } else {
              sortedData = sortReviewsByDate(data.allReviews.nodes)
            }
            return (
              <div id={this.props.slideID}>
                <Slider {...settings} style={{ ...sliderStyle }}>
                  {sortedData.map((node) => (
                    <SingleSlide
                      key={node.title}
                      title={node.title.toLowerCase()}
                      reviewerName={node.reviewerName}
                      procedure={node.mainProcedureDone}
                      thumbnailPublicId={node.thumbnailPublicId}
                      monthYear={node.monthYear}
                      language={this.props.language}
                    />
                  ))}
                </Slider>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
