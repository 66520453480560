import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"

import ImageMeta from "./ImageMeta"

import ButtonGroupMap from "./Button/ButtonGroupMap"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"

const LocationInfo = ({ data, location, relatedLocations, napRef }) => {
  const combinedLocations = [location].concat(relatedLocations)

  const allowedSocials = ["Facebook", "Instagram"]

  return (
    <div className="location-info row color-back" id="nap">
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <h3 className="has-text-centered-desktop" id="nap" ref={napRef}>
            {data.heading}
          </h3>
          <MarkdownViewer markdown={data.text} />
        </div>
        <div className="column is-2"></div>
      </div>

      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <div className="map-address-grid">
            <div className="info-address">
              {combinedLocations.map((info) => (
                <div className="location-block" key={info.locationName}>
                  <h4>Contact Us in {info.locationName}</h4>
                  {!data.showAddress ? (
                    ""
                  ) : (
                    <div>
                      <a
                        href={info.googlePlaceLink}
                        target="_blank"
                        title={`Get directions to our ${info.locationName} location.`}
                      >
                        {`${info.streetNumber} ${info.streetName}`}
                        <div className="address-spacer"></div>
                        {`${info.cityName}, ${info.stateAbbr} ${info.cityZip}`}
                      </a>
                    </div>
                  )}

                  {!data.showPhone ? (
                    ""
                  ) : (
                    <div className="mt-1">
                      <h7>Phone</h7>
                      <a href={`tel:+1${info.phone}`} title="Give us a call">
                        {info.phone}
                      </a>
                    </div>
                  )}

                  <div className="mt-1">
                    <h7>Fax</h7>
                    <p style={{ padding: 0 }}>{info.fax}</p>
                  </div>

                  {!data.showEmail ? (
                    ""
                  ) : (
                    <div className="mt-1">
                      <h7>Email</h7>
                      <a href={`mailto:${info.email}`} title="Email us">
                        {info.email}
                      </a>
                    </div>
                  )}

                  <GeneralHours
                    className="mt-1 hours"
                    language="en"
                    contact={true}
                    locationHours={location.locationHours}
                  />

                  <div className="socials">
                    {info.socialAccounts.map((social) => (
                      <React.Fragment key={social.platform}>
                        {allowedSocials.includes(social.platform) && (
                          <a
                            href={social.link}
                            title={`Follow us on ${social.platform}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <SocialIcon social={social} />
                          </a>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
              <div className="other-location-buttons">
                <h4>Other Locations</h4>
                <div>
                  <ButtonGroupMap buttons={data.buttons} />
                </div>
              </div>
            </div>

            <div className="info-map">
              <ImageMeta
                cloudName="nuvolum"
                publicId={data.imageId}
                width="auto"
                responsive
              />
            </div>
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

const SocialIcon = ({ social }) => {
  let icon
  if (social.platform === "Facebook") {
    icon = faFacebookF
  }
  if (social.platform === "Instagram") {
    icon = faInstagram
  }

  return (
    <div className="icon-circle">
      <FontAwesomeIcon className="button-icon" icon={icon} />
    </div>
  )
}

export default LocationInfo
